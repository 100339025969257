import customerService from "../../services";

export default {
    data() {
        return {
            customer:"",
        }
    },
    mounted() {
        this.showLoader(true);
        customerService.getCustomer(this.$route.params.id).then(response => {
            this.customer = response.data;
            this.hideLoader();
            console.log(response.data)
        });
    }
}