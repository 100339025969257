<app-header>
    <a href="#" class="header-icon header-icon-1 d-flex align-items-center justify-content-center"
        v-if="$store.state.user.user_type != 'super_admin'">
        <i class="fas fa-user-circle font-22 primary-alt-light-text"></i>
    </a>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Customers</h1>
    <a href="#" data-menu="searchMenu" class="header-icon header-icon-2">
        <i class="fa fa-search"></i>
    </a>
    <div class="header-icon header-icon-3 select-filter" style="width: 50px !important;" v-if="hasSuperAdmin">
        <app-select class="border-no custom-dropdown" name="country_id" placeholder=""
            :options="$store.state.selectData.countries" value-prop="id" v-model="country_id">
            <template v-slot:default="slotProps">
                {{ slotProps.item.emoji }}
            </template>
            <template v-slot:display="slotProps">
                <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                {{ slotProps.item.name }}
            </template>
        </app-select>
    </div>
</app-header>

<div class="page-title-clear"></div>
<div class="page-content">
    <div class="text-center" v-if="searchedResultShow">
        <p class="font-15 font-200 my-2 letterSpacing text-secondary text-center text-capitalize">
            Search results for
            <span class="font-500 text-dark">'{{ search }}'</span>
            <button @click="filterClear" class="btn gradient-red btn-xs letterSpacing font-500 ms-3"
                style="padding: 3px 10px !important;">Clear</button>
        </p>
    </div>
    <div v-for="(record, index) in customers" class="card card-style mt-2 mb-1"
        v-if="customers && customers.length > 0">
        <div class="content mb-2 mt-2">
            <router-link :to="{'name': 'customer-detail', params: {'id' : record.id}}">
                <div class="d-flex">
                    <div class="align-self-center">
                        <h2 class="primary-light-text font-600 font-15 line-height-s mt-1 mb-1 letterSpacing">
                            {{ record.First_Name }} {{ record.Last_Name }}
                        </h2>
                        <p class="font-12 line-height-s mt-2 mb-1 letterSpacing font-600">
                            Lead: <span class="primary-alt-base-text">{{ record.Lead_Source ?
                                record.Lead_Source : "-"
                                }}</span>
                        </p>
                    </div>
                    <div class="ms-auto ps-3 align-items-end">
                        <p class="font-10 mb-1 text-end">{{ localDate(record.Created_Time) }}</p>
                        <p class="font-12 mb-0 text-end font-600"
                        :class="[ record.partner_name ? 'partnerNameBagde' : 'text-center' ]">
                            {{ record.partner_name ? record.partner_name : "--" }}
                        </p>
                    </div>
                    <div class="d-flex ps-2 align-items-center">
                        <i class="fa fa-chevron-right font-10 text-secondary"></i>
                    </div>
                </div>
            </router-link>
        </div>
    </div>
    <div class="mt-5 text-center" v-if="!(customers.length > 0)">
        <i class="fas fa-search font-20 text-secondary mb-3"></i>
        <p class="font-15 font-600 letterSpacing text-secondary text-center text-capitalize">
            No Customer Found
        </p>
    </div>
</div>
<div id="searchMenu" class="menu menu-box-bottom rounded-m" data-menu-height="300" data-menu-effect="menu-over">
    <div class="menu-title p-3 pb-2">
        <h2 class="letterSpacing font-17 font-200">Search By</h2>
        <a href="#" class="close-menu" ref="closeBtn"><i class="fa fa-times-circle"></i></a>
    </div>
    <div class="card-style shadow-0 rounded-0 mb-0">
        <div class="input-style has-borders">
            <input class="form-control font-15 font-400 letterSpacing" type="text" placeholder="Search " v-model="search">
            <em></em>
            <i class="disabled invalid"></i>
            <i class="disabled valid"></i>
        </div>
    </div>
    <div class="content mt-0 d-flex justify-content-center">

        <button @click="filterUpdate" :disabled="!search"
            class="close-menu btn btn-l btn-ripple me-1 primary-light-bg rounded-s letterSpacing">
            Search
        </button>
        <button @click="filterClear" :disabled="!search"
            class="close-menu btn btn-l btn-ripple primary-alt-light-bg rounded-s letterSpacing">
            Clear
        </button>
    </div>
</div>
<app-footer />