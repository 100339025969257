import api from "@/common/services/api";

export default new class {
    baseUrl = "/customers";
    
    getSelectData(data) {
        return api.instance.get("/get-select-data", {params: data});
    }

    getCustomers(data) {
        return api.instance.get(this.baseUrl, {params: data});
    }

    getCustomer(id) {
        return api.instance.get(this.baseUrl + '/' +id);
    }
};